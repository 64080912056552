/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HistoryStatisticDto } from '../../models/history-statistic-dto';
import { Interval } from '../../models/interval';

export interface GetHistory$Params {
  interval?: Interval;

/**
 * Defaults to the customer of the current user. If another customer is requested, requires explicit permissions to this customer.
 */
  customerId?: any;

/**
 * Duration in days.
 *          Will return the series for the last n days including today.
 *          This means if requesting a duration of 30, the series will contain at most 30 data points.
 *          Example: If today is 18.09. the data points will be in time range 02.08.-18.09.
 */
  duration?: any;
}

export function getHistory(http: HttpClient, rootUrl: string, params?: GetHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<HistoryStatisticDto>> {
  const rb = new RequestBuilder(rootUrl, getHistory.PATH, 'get');
  if (params) {
    rb.query('interval', params.interval, {});
    rb.query('customerId', params.customerId, {});
    rb.query('duration', params.duration, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<HistoryStatisticDto>;
    })
  );
}

getHistory.PATH = '/statistics/connection-requests/history';
