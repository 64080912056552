import { Pipe, PipeTransform } from '@angular/core';
import { CreatedByDto } from 'src/api/dso-portal/generated/models';
import { ContactDetailsDto } from 'src/api/dso-portal/generated/models/contact-details-dto';

@Pipe({
  name: 'contactName',
  standalone: true,
})
export class ContactNamePipe implements PipeTransform {
  transform(input: ContactDetailsDto | CreatedByDto | undefined): string {
    return `${input?.lastName}, ${input?.firstName}`;
  }
}
