import { IEnvironment } from './ienvironment';

export const environment: IEnvironment = {
  production: true,
  environment: '$ENVIRONMENT',
  analyticsTagId: '$ANALYTICS_TAG_ID',
  auth0ClientId: '$AUTH0_CLIENT_ID',
  auth0Domain: '$AUTH0_DOMAIN',
  apiUrl: '$API_URL',
  servicePortalApiUrl: '$SERVICE_PORTAL_API_URL',
  servicePortalUrl: '$SERVICE_PORTAL_URL',
  gtmId: '$GTM_ID',
  gtmAuth: '$GTM_AUTH',
  gtmPreview: '$GTM_PREVIEW',
};
