import { SideNavigationLink } from '@app/index';
import { NavigationItem } from '@eon-one/one-ui';

export class NavigationItemMapper {
  static map(item: SideNavigationLink): NavigationItem {
    return {
      title: item.translationKey,
      route: item.route,
      appearOnlyOn: ['mobile'],
    };
  }
}
