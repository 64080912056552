import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize } from 'rxjs';
import { LoadingService } from '../services';
import { environment } from '@environments/index';

const endpointsRequiringLoadingIndicator = [
  environment.apiUrl,
  environment.servicePortalApiUrl,
];

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const isLoadingIndicatorRequired = endpointsRequiringLoadingIndicator.some(
    endpoint => req?.url?.includes(endpoint)
  );
  if (isLoadingIndicatorRequired) {
    const loadingService = inject(LoadingService);
    loadingService.increaseCounter();
    return next(req).pipe(
      finalize(() => {
        loadingService.decreaseCounter();
      })
    );
  } else {
    return next(req);
  }
};
