export const PRODUCT_NAME = 'OnePortal';

export type LanguageKey = 'en' | 'de';

export type LanguageLocale = {
  label: string;
  locale: LanguageKey;
  localeCulture?: string;
};

export const APP_LANGUAGE: Record<LanguageKey, LanguageLocale> = {
  en: {
    label: 'English',
    locale: 'en',
    localeCulture: 'en-GB',
  },
  de: {
    label: 'Deutsch',
    locale: 'de',
    localeCulture: 'de',
  },
} as const;

export const DEFAULT_APP_LANGUAGE = APP_LANGUAGE.de;

export const DEFAULT_TABLE_PAGE_SIZE = 10;
