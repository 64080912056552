@if (isSaveInProgress) {
  <dso-loading-spinner data-testid="loading-spinner" />
}
<div class="dialog">
  <div class="dialog__header">
    <h2 class="dialog__headline">
      {{
        'CHANGE_STATUS_DIALOG'
          | translate
            : { status: 'REQUESTS_DETAILS.STATUS_' + data.status | translate }
      }}
    </h2>
    <dso-icon-button mat-dialog-close icon="close" size="medium" />
  </div>

  <form [formGroup]="statusForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <div class="custom-mat-select">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'COMMON.REQUEST_STATUS' | translate }}</mat-label>
          <mat-select
            data-testid="status-select"
            [formControlName]="FORM_FIELD.SELECTED_STATUS">
            @for (
              statusMeta of statusMetaInfos;
              track statusMeta.translationIdentifier
            ) {
              <mat-option [value]="statusMeta.status">
                {{ statusMeta.translationIdentifier | translate }}
              </mat-option>
            }
          </mat-select>
          @if (
            statusForm.get(FORM_FIELD.SELECTED_STATUS)?.hasError('required')
          ) {
            <mat-error>{{ 'FORMS.REQUIRED_ERROR' | translate }}</mat-error>
          }
        </mat-form-field>
      </div>
      <p class="dialog__lead">
        {{ 'CHANGE_STATUS.NOTIFY_CUSTOMER' | translate }}
      </p>
      <mat-slide-toggle
        class="dialog__slider"
        color="primary"
        [formControlName]="FORM_FIELD.NOTIFY_CUSTOMER">
        <span class="dialog__slider__text">
          {{ 'CHANGE_STATUS.AUTOMATED_MESSAGE_TO_CUSTOMER' | translate }}
        </span>
      </mat-slide-toggle>
    </mat-dialog-content>

    <div class="dialog__footer">
      <mat-dialog-actions align="end">
        <one-button
          size="medium"
          mat-dialog-close
          variant="secondary"
          data-testid="status-select-cancel-btn">
          {{ 'COMMON.CANCEL' | translate }}
        </one-button>
        <one-button
          data-testid="status-select-submit-btn"
          type="submit"
          [disabled]="statusForm.invalid"
          size="medium">
          {{ 'COMMON.SAVE' | translate }}
        </one-button>
      </mat-dialog-actions>
    </div>
  </form>
</div>
