@if (statusMeta(); as statusMeta) {
  @let lowerCaseStatus = statusMeta.status.toLowerCase();
  <div
    [class]="'status-indicator status-indicator--' + lowerCaseStatus"
    [ngClass]="{
      'status-indicator--small': size() === 'small',
      'status-indicator--big': size() === 'big',
    }">
    <div class="status-indicator__start">
      <mat-icon
        class="status-indicator__icon"
        [ngClass]="{
          'dso-icon': size() === 'small',
          'dso-icon--big': size() === 'big',
        }"
        [attr.aria-label]="statusMeta.altTextTranslationIdentifier | translate"
        [svgIcon]="statusMeta.icon" />
      <span class="status-indicator__text status-indicator--text-ellipsis">
        {{ statusMeta.translationIdentifier | translate }}
      </span>
    </div>
    <div>
      @if (size() === 'big' && total() !== undefined) {
        <div class="status-indicator__number">{{ total() }}</div>
      }
    </div>
  </div>
}
