import { MatDialogConfig } from '@angular/material/dialog';

export class DialogConfigFactory {
  private static readonly DEFAULT_DISABLE_CLOSE = true;
  private static readonly AUTO_FOCUS = false;

  public static build(
    width: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    disableClose = DialogConfigFactory.DEFAULT_DISABLE_CLOSE,
    autoFocus = DialogConfigFactory.AUTO_FOCUS
  ): MatDialogConfig {
    return {
      width,
      disableClose,
      autoFocus,
      data,
    };
  }
}
