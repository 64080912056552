import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs';
import { ProductSubscriptionDto, SelfService } from '@app/core/services';
import { GLOBAL_ERROR_TYPE } from '@app/shared';
import { Observable } from 'rxjs/internal/Observable';

export const addonCheckGuardFn: () => Observable<boolean> = () => {
  const selfService = inject(SelfService);
  const router = inject(Router);

  return selfService.getSelf().pipe(
    map(
      user =>
        !!user.productSubscriptions?.some(
          subscription =>
            !!(subscription as ProductSubscriptionDto).addons?.some(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              addon => addon.key === 'request-management'
            )
        )
    ),
    tap(hasAddon => {
      if (!hasAddon) {
        router.navigate(['/error'], {
          state: { errorType: GLOBAL_ERROR_TYPE.ADDON_NOT_ENABLED },
          skipLocationChange: true,
        });
      }
    })
  );
};
