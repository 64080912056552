/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addConnectionRequestComment } from '../fn/connection-requests/add-connection-request-comment';
import { AddConnectionRequestComment$Params } from '../fn/connection-requests/add-connection-request-comment';
import { CommentDto } from '../models/comment-dto';
import { ConnectionRequestDto } from '../models/connection-request-dto';
import { downloadConnectionRequest } from '../fn/connection-requests/download-connection-request';
import { DownloadConnectionRequest$Params } from '../fn/connection-requests/download-connection-request';
import { downloadDocument } from '../fn/connection-requests/download-document';
import { DownloadDocument$Params } from '../fn/connection-requests/download-document';
import { getConnectionRequest } from '../fn/connection-requests/get-connection-request';
import { GetConnectionRequest$Params } from '../fn/connection-requests/get-connection-request';
import { getConnectionRequestActivities } from '../fn/connection-requests/get-connection-request-activities';
import { GetConnectionRequestActivities$Params } from '../fn/connection-requests/get-connection-request-activities';
import { getConnectionRequests } from '../fn/connection-requests/get-connection-requests';
import { GetConnectionRequests$Params } from '../fn/connection-requests/get-connection-requests';
import { PagedResponseConnectionRequestDto } from '../models/paged-response-connection-request-dto';
import { ResponseWrapperListActivityDto } from '../models/response-wrapper-list-activity-dto';
import { StreamingResponseBody } from '../models/streaming-response-body';
import { updateConnectionRequestStatus } from '../fn/connection-requests/update-connection-request-status';
import { UpdateConnectionRequestStatus$Params } from '../fn/connection-requests/update-connection-request-status';


/**
 * This API is used to get connection requests and its details. Also it provides management feature like adding comments or managing the current status.
 */
@Injectable({ providedIn: 'root' })
export class ConnectionRequestsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateConnectionRequestStatus()` */
  static readonly UpdateConnectionRequestStatusPath = '/connection-requests/{id}/status';

  /**
   * Update connection request status by id.
   *
   * Allowed permissions: **custom/connectionRequest/status/update**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateConnectionRequestStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateConnectionRequestStatus$Response(params: UpdateConnectionRequestStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<ConnectionRequestDto>> {
    return updateConnectionRequestStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Update connection request status by id.
   *
   * Allowed permissions: **custom/connectionRequest/status/update**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateConnectionRequestStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateConnectionRequestStatus(params: UpdateConnectionRequestStatus$Params, context?: HttpContext): Observable<ConnectionRequestDto> {
    return this.updateConnectionRequestStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConnectionRequestDto>): ConnectionRequestDto => r.body)
    );
  }

  /** Path part for operation `addConnectionRequestComment()` */
  static readonly AddConnectionRequestCommentPath = '/connection-requests/{id}/comments';

  /**
   * Add comment to connection request.
   *
   * Allowed permissions: **custom/connectionRequest/comment/add**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addConnectionRequestComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addConnectionRequestComment$Response(params: AddConnectionRequestComment$Params, context?: HttpContext): Observable<StrictHttpResponse<CommentDto>> {
    return addConnectionRequestComment(this.http, this.rootUrl, params, context);
  }

  /**
   * Add comment to connection request.
   *
   * Allowed permissions: **custom/connectionRequest/comment/add**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addConnectionRequestComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addConnectionRequestComment(params: AddConnectionRequestComment$Params, context?: HttpContext): Observable<CommentDto> {
    return this.addConnectionRequestComment$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommentDto>): CommentDto => r.body)
    );
  }

  /** Path part for operation `getConnectionRequests()` */
  static readonly GetConnectionRequestsPath = '/connection-requests';

  /**
   * Get connection requests.
   *
   * Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConnectionRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectionRequests$Response(params?: GetConnectionRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedResponseConnectionRequestDto>> {
    return getConnectionRequests(this.http, this.rootUrl, params, context);
  }

  /**
   * Get connection requests.
   *
   * Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConnectionRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectionRequests(params?: GetConnectionRequests$Params, context?: HttpContext): Observable<PagedResponseConnectionRequestDto> {
    return this.getConnectionRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagedResponseConnectionRequestDto>): PagedResponseConnectionRequestDto => r.body)
    );
  }

  /** Path part for operation `getConnectionRequest()` */
  static readonly GetConnectionRequestPath = '/connection-requests/{id}';

  /**
   * Get connection request by id.
   *
   * Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConnectionRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectionRequest$Response(params: GetConnectionRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<ConnectionRequestDto>> {
    return getConnectionRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * Get connection request by id.
   *
   * Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConnectionRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectionRequest(params: GetConnectionRequest$Params, context?: HttpContext): Observable<ConnectionRequestDto> {
    return this.getConnectionRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConnectionRequestDto>): ConnectionRequestDto => r.body)
    );
  }

  /** Path part for operation `downloadConnectionRequest()` */
  static readonly DownloadConnectionRequestPath = '/connection-requests/{id}/download';

  /**
   * Download connection request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadConnectionRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadConnectionRequest$Response(params: DownloadConnectionRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<StreamingResponseBody>> {
    return downloadConnectionRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * Download connection request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadConnectionRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadConnectionRequest(params: DownloadConnectionRequest$Params, context?: HttpContext): Observable<StreamingResponseBody> {
    return this.downloadConnectionRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<StreamingResponseBody>): StreamingResponseBody => r.body)
    );
  }

  /** Path part for operation `downloadDocument()` */
  static readonly DownloadDocumentPath = '/connection-requests/{id}/documents/{documentId}/download';

  /**
   * Download a document of connection request.
   *
   * Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDocument$Response(params: DownloadDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<StreamingResponseBody>> {
    return downloadDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Download a document of connection request.
   *
   * Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDocument(params: DownloadDocument$Params, context?: HttpContext): Observable<StreamingResponseBody> {
    return this.downloadDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<StreamingResponseBody>): StreamingResponseBody => r.body)
    );
  }

  /** Path part for operation `getConnectionRequestActivities()` */
  static readonly GetConnectionRequestActivitiesPath = '/connection-requests/{id}/activities';

  /**
   * Get connection request activities by id.
   *
   * Activities are sorted by creation date descending. Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConnectionRequestActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectionRequestActivities$Response(params: GetConnectionRequestActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseWrapperListActivityDto>> {
    return getConnectionRequestActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * Get connection request activities by id.
   *
   * Activities are sorted by creation date descending. Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConnectionRequestActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectionRequestActivities(params: GetConnectionRequestActivities$Params, context?: HttpContext): Observable<ResponseWrapperListActivityDto> {
    return this.getConnectionRequestActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseWrapperListActivityDto>): ResponseWrapperListActivityDto => r.body)
    );
  }

}
