import {
  ConnectionRequestDto,
  ContactDetailsDto,
  CustomerConfigurationDto,
  FlowType,
  FormModule,
  FormRequestType,
  LocationDto,
  StatusType,
} from 'src/api/dso-portal/generated/models';

const flowTypeMapping = new Map<ExtendedFormModule, FlowType[]>([
  [
    'ELECTRICITY',
    [
      'GRID_CONNECTION',
      'PV',
      'BALCONY_PV',
      'CHARGING_DEVICE',
      'HEAT_PUMP',
      'STORAGE',
      'OTHER',
    ],
  ],
  ['GAS', ['GRID_CONNECTION']],
  ['WATER', ['GRID_CONNECTION', 'SEWAGE', 'CONSTRUCTION_WATER']],
  ['HEAT', ['GRID_CONNECTION']],
  ['ELECTRICITY_COMMISSIONING', ['MAIN_POWER_SUPPLY', 'CUSTOMER_FACILITY']],
]);

export interface RequestTableData {
  id?: string;
  module: FormModule;
  requestType: FormRequestType;
  requestId: string;
  flowType: FlowType;
  location: LocationDto;
  requesterContact: ContactDetailsDto;
  createdAt: string;
  status: StatusType;
}

export type ExtendedFormModule = FormModule | CommissioningFormModule;

export type CommissioningFormModule = `${FormModule}_COMMISSIONING`;

export class RequestsHelper {
  static mapModules(
    configuration: CustomerConfigurationDto
  ): Array<ExtendedFormModule> {
    const registrationModules: ExtendedFormModule[] =
      configuration.moduleSettings.map(moduleSettings => moduleSettings.module);
    const commissioningModules: ExtendedFormModule[] =
      configuration.commissioningModules.map(
        module => `${module}_COMMISSIONING` as ExtendedFormModule
      );
    return registrationModules.concat(commissioningModules);
  }

  static mapFlowTypes(selectedModules: ExtendedFormModule[]): FlowType[] {
    let availableFlowTypes: FlowType[] = [];
    selectedModules.forEach(module => {
      availableFlowTypes = availableFlowTypes.concat(
        flowTypeMapping.get(module)!
      );
    });
    return [...new Set(availableFlowTypes)];
  }

  static mapRequestList(
    requestList: ConnectionRequestDto[]
  ): RequestTableData[] {
    return requestList.map(request => ({
      id: request.id,
      module: request.module,
      requestId: request.requestId,
      requestType: request.requestType,
      flowType: request.flowType,
      location: request.location,
      requesterContact: request.requesterContact,
      createdAt: request.createdAt,
      status: request.currentStatus.status,
    }));
  }

  static isRegistrationModule(
    module: ExtendedFormModule
  ): module is FormModule {
    return (
      module === 'ELECTRICITY' ||
      module === 'GAS' ||
      module === 'WATER' ||
      module === 'HEAT'
    );
  }

  static evaluateRequestType(
    moduleList: ExtendedFormModule[] | null | undefined
  ): Array<FormRequestType> {
    const requestType: Array<FormRequestType> = [];
    if (moduleList) {
      if (
        moduleList.some(module => RequestsHelper.isRegistrationModule(module))
      ) {
        requestType.push('REGISTRATION');
      }
      if (moduleList.some(module => isCommissioningModule(module))) {
        requestType.push('COMMISSIONING');
      }
    }
    return requestType;
  }

  static evaluateFormModules(
    moduleList: FormModule[],
    requestType: FormRequestType[]
  ): ExtendedFormModule[] {
    if (moduleList.length === 0) {
      return [];
    }

    const formModules = new Set<ExtendedFormModule>(
      moduleList.filter(module => module !== 'ELECTRICITY')
    );

    if (moduleList.includes('ELECTRICITY')) {
      if (requestType.includes('COMMISSIONING')) {
        formModules.add('ELECTRICITY_COMMISSIONING');
      }

      if (requestType.includes('REGISTRATION')) {
        formModules.add('ELECTRICITY');
      }
    }

    return Array.from(formModules);
  }
}

function isCommissioningModule(
  module: ExtendedFormModule
): module is CommissioningFormModule {
  return module === 'ELECTRICITY_COMMISSIONING';
}
