/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ActivityExpand } from '../../models/activity-expand';
import { ResponseWrapperListActivityDto } from '../../models/response-wrapper-list-activity-dto';

export interface GetConnectionRequestActivities$Params {
  id: string;
  expand?: Array<ActivityExpand>;
}

export function getConnectionRequestActivities(http: HttpClient, rootUrl: string, params: GetConnectionRequestActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseWrapperListActivityDto>> {
  const rb = new RequestBuilder(rootUrl, getConnectionRequestActivities.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('expand', params.expand, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseWrapperListActivityDto>;
    })
  );
}

getConnectionRequestActivities.PATH = '/connection-requests/{id}/activities';
