<div class="snack-bar dso-icon">
  @if (svgSource(); as svgSource) {
    <mat-icon
      class="dso-icon"
      [svgIcon]="svgSource"
      [attr.aria-label]="svgAltText() ? (svgAltText()! | translate) : ''" />
  }
  @if (message(); as message) {
    <p>{{ message | translate }}</p>
  }
</div>
